<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Реєстрація змін
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="houseParameterHeaders"
        :items="items"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="openItemModal"
    >
      <template v-slot:item.icon>
        <v-icon color="success" size="26" class="mr-2">mdi-ruler</v-icon>
      </template>

      <template v-slot:item.changes_section_type="{ item }">
        <span>
          {{ getRegistrationOfChangesSectionType(item.changes_section_type) }}
        </span>
      </template>

      <template v-slot:item.month="{ item }">
        <span>
          {{ item.month | formatDate('MMMM YYYY') }}
        </span>
      </template>

      <template v-slot:item.create_date="{ item }">
        <span>
          {{ item.create_date | formatDate('DD.MM.YYYY HH:mm:ss') }}
        </span>
      </template>

      <template v-slot:item.recalculation_date="{ item }">
        <span>
          {{ item.recalculation_date | formatDate }}
        </span>
      </template>

    </v-data-table>
  </v-card>
</template>

<script>

// import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {
  GET_ALL_REGISTRATION_OF_CHANGES,
} from "@/store/actions/registration_of_changes";
import {getRegistrationOfChangesSectionType} from "@/utils/icons"
export default {
  name: "HWP_RegistrationOfChanges",
  // components: { MainModal },
  data() {
    return {
      item_dialog_id: 0,
      houseParameterHeaders: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Місяць', value: 'month' },
        { text: 'Дата реєстр.', value: 'create_date' },
        { text: 'Дата перерах.', value: 'recalculation_date' },
        { text: 'Розділ обліку', value: 'changes_section_type' },
        { text: 'Опис', value: 'description' },
        { text: 'Коментар', value: 'comments' },
        { text: 'Автор', value: 'owner_name', width: 120 },
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'getRegistrationOfChanges',
      flat: 'getFlat',
      settings: 'getGeneralSettings'
    }),
  },
  methods: {
    ...mapActions({
      fetchRegistrationOfChanges: GET_ALL_REGISTRATION_OF_CHANGES,
    }),
    getRegistrationOfChangesSectionType,
    updateItemModal(payload) {
      this.item_dialog_id = 0
      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openItemModal(row) {
      this.item_dialog_id = row.id
    }
  },
  created() {
    this.fetchRegistrationOfChanges(this.$route.params.person_hash)
  }
}
</script>

<style scoped lang="scss">

</style>